import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Divider, Flex, Heading, HStack, IconButton, Link, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SolidButton } from "../../../../components/Buttons/SolidButton";
import { MainBoard } from "../../../../components/MainBoard";
import { api } from "../../../../services/api";
import { BillCategory, Partner, PaymentCategory, QuotaSale, Sum } from "../../../../types";
import { RemoveButton } from "../../../../components/Buttons/RemoveButton";
import { EditButton } from "../../../../components/Buttons/EditButton";
import { OutlineButton } from "../../../../components/Buttons/OutlineButton";
import SortableTable, { SortableTableData } from "../../../../components/SortableTable";
import { formatBRDate } from "../../../../utils/Date/formatBRDate";
import { CancelQuotaSaleData, ConfirmQuotaSaleCancelModal } from "../../Sales/ConfirmQuotaSaleCancelModal";
import { ConfirmQuotaSaleRemoveModal, RemoveQuotaSaleData } from "../../Sales/ConfirmQuotaSaleRemoveModal";
import { EditBillFormData } from "../../../Financial/Bills/EditBillModal";
import { ReceiveBillFormData } from "../../../Financial/Bills/ReceiveBillModal";
import { PayPaymentFormData } from "../../../Financial/Payments/PayPaymentModal";
import { showErrors } from "../../../../hooks/useErrors";
import { EditPaymentFormData } from "../../../Financial/Payments/EditPaymentModal";

import { ReactComponent as BackArrow } from '../../../../assets/icons/Back Arrow.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/Check.svg';
import { ReactComponent as MinusIcon } from '../../../../assets/icons/Minus.svg';
import { ReactComponent as StrongPlusIcon } from '../../../../assets/icons/StrongPlus.svg';
import { ReactComponent as HomeIcon } from '../../../../assets/icons/Home.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/Close.svg';
import { ReactComponent as RefreshIcon } from '../../../../assets/icons/Refresh.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';
import Badge from "../../../../components/Badge";

interface PartnerParams{
    id: string;
}

interface RemovePaymentData{
    id: number;
    title: string;
}

interface RemoveBillData{
    id: number;
    title: string;
}

export default function PartnerPage(){
    const { id } = useParams<PartnerParams>();
    const history = useHistory();
    const [partnerSumsData, setPartnerSumsData] = useState<SortableTableData[]>([]);
    const [soldQuotasTableData, setSoldQuotasTableData] = useState<SortableTableData[]>([]);
    //const [boughtQuotasTableData, setBoughtQuotasTableData] = useState<SortableTableData[]>([]);
    const [partnerSums, setPartnerSums] = useState<Sum[]>();
    const [partner, setPartner] = useState<Partner>();

    const loadPartnerData = async () => {
        const { data } = await api.get(`/partnerData/${id}`);

        setPartner(data);
    }

    const loadPartnerSums = async () => {
        const { data } = await api.get(`/proxy/sums`, {params: {slug: partner?.slug}});

        setPartnerSums(data);
    }

    useEffect(() => {
        loadPartnerData();
    }, []);

    const [isConfirmQuotaSaleCancelModalOpen, setisConfirmQuotaSaleCancelModalOpen] = useState(false);
    const [cancelQuotaSaleData, setCancelQuotaSaleData] = useState<CancelQuotaSaleData>(() => {

        const data: CancelQuotaSaleData = {
            //group: '',
            //quota: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmQuotaSaleCancelModal(QuotaData: CancelQuotaSaleData){
        setCancelQuotaSaleData(QuotaData);
        setisConfirmQuotaSaleCancelModalOpen(true);
    }
    function CloseConfirmQuotaSaleCancelModal(){
        setisConfirmQuotaSaleCancelModalOpen(false);
    }

    const [isConfirmQuotaSaleRemoveModalOpen, setisConfirmQuotaSaleRemoveModalOpen] = useState(false);
    const [removeQuotaSaleData, setRemoveQuotaSaleData] = useState<RemoveQuotaSaleData>(() => {

        const data: RemoveQuotaSaleData = {
            //group: '',
            //quota: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmQuotaSaleRemoveModal(QuotaData: RemoveQuotaSaleData){
        setRemoveQuotaSaleData(QuotaData);
        setisConfirmQuotaSaleRemoveModalOpen(true);
    }
    function CloseConfirmQuotaSaleRemoveModal(){
        setisConfirmQuotaSaleRemoveModalOpen(false);
    }

    const [isReceiveBillModalOpen, setIsReceiveBillModalOpen] = useState(false);
    const [toReceiveBillData, setToReceiveBillData] = useState<ReceiveBillFormData>(() => {

        const data: ReceiveBillFormData = {
            id: 0,
            value: 0,
            paid: 0,
            new_value: '',
            title: '',
        };
        
        return data;
    });

    function OpenReceiveBillModal(billIdAndName: ReceiveBillFormData){
        setToReceiveBillData(billIdAndName);
        setIsReceiveBillModalOpen(true);
    }
    function CloseReceiveBillModal(){
        setIsReceiveBillModalOpen(false);
    }

    const [isPayPaymentModalOpen, setIsPayPaymentModalOpen] = useState(false);
    const [toPayPaymentData, setToPayPaymentData] = useState<ReceiveBillFormData>(() => {

        const data: ReceiveBillFormData = {
            id: 0,
            value: 0,
            paid: 0,
            new_value: '',
            title: '',
        };
        
        return data;
    });

    function OpenPayPaymentModal(billIdAndName: PayPaymentFormData){
        setToPayPaymentData(billIdAndName);
        setIsPayPaymentModalOpen(true);
    }
    function ClosePayPaymentModal(){
        setIsPayPaymentModalOpen(false);
    }

    const toast = useToast();

    const handleReverseBill = async (billId : number) => {
        try{
            await api.post(`/bills/unreceive/${billId}`);

            toast({
                title: "Sucesso",
                description: `Conta a receber redefinda como não recebida.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            loadPartnerData();
        }catch(error: any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const [isEditPaymentModalOpen, setIsEditPaymentModalOpen] = useState(false);

    function OpenEditPaymentModal(paymentData: EditPaymentFormData){
        setToEditPaymentData(paymentData);
        setIsEditPaymentModalOpen(true);
    }

    function CloseEditPaymentModal(){
        setIsEditPaymentModalOpen(false);
    }

    const [toEditPaymentData, setToEditPaymentData] = useState<EditPaymentFormData>(() => {

        const data: EditPaymentFormData = {
            id: 0,
            title: '',
            value: '',
            paid: '',
            company: 0,
            category: 0,
            provider: 0,
            pay_to_user: 0,
            //status: false,
            expire: '',
            observation: '',
            contract: '',
            group: '',
            quote: '',
            recurrence: 0,
            file: [],
        };
        
        return data;
    });

    const [isConfirmPaymentRemoveModalOpen, setisConfirmPaymentRemoveModalOpen] = useState(false);
    const [removePaymentData, setRemovePaymentData] = useState<RemovePaymentData>(() => {

        const data: RemovePaymentData = {
            title: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmPaymentRemoveModal(paymentData: RemovePaymentData){
        setRemovePaymentData(paymentData);
        setisConfirmPaymentRemoveModalOpen(true);
    }
    function CloseConfirmPaymentRemoveModal(){
        setisConfirmPaymentRemoveModalOpen(false);
    }

    const [toEditBillData, setToEditBillData] = useState<EditBillFormData>(() => {

        const data: EditBillFormData = {
            id: 0,
            title: '',
            value: '',
            company: 0,
            category: 0,
            source: 0,
            expire: '',
            observation: '',
        };
        
        return data;
    });

    const [isEditBillModalOpen, setIsEditBillModalOpen] = useState(false);

    function OpenEditBillModal(BillData : EditBillFormData){
        setToEditBillData(BillData);
        setIsEditBillModalOpen(true);
    }
    function CloseEditBillModal(){
        setIsEditBillModalOpen(false);
    }

    const [isConfirmBillRemoveModalOpen, setisConfirmBillRemoveModalOpen] = useState(false);
    const [removeBillData, setRemoveBillData] = useState<RemoveBillData>(() => {

        const data: RemoveBillData = {
            title: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmBillRemoveModal(BillData: RemoveBillData){
        setRemoveBillData(BillData);
        setisConfirmBillRemoveModalOpen(true);
    }
    function CloseConfirmBillRemoveModal(){
        setisConfirmBillRemoveModalOpen(false);
    }

    const [paymentCategories, setPaymentCategories] = useState<PaymentCategory[]>([]);
    const [billCategories, setBillCategories] = useState<BillCategory[]>([]);

    
    const quotaColumns = [
        {
            key: "date", 
            label: "Data", 
            sortable: true
        },
        {
            key: "name", 
            label: "Grupo e cota", 
            sortable: true
        },
        {
            key: "credit", 
            label: "Crédito", 
            sortable: true
        },
        {
            key: "value", 
            label: "Entrada", 
            sortable: true
        },
    ]

    useEffect(() => {
        loadPartnerSums();

        const newSoldQuotasTableData = partner?.sold_quotas?.map((readyQuota) => {
            return {
                date: readyQuota.purchase_date ? readyQuota.purchase_date : "",
                credit: readyQuota.credit ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(readyQuota.credit) : 0,
                entry: readyQuota.value ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(readyQuota.value) : 0,
                name: (readyQuota.group && readyQuota.quota) ? `Grupo:${readyQuota.group} / ${readyQuota.quota}` : 0,
            }
        });

        if(newSoldQuotasTableData){
            setSoldQuotasTableData(newSoldQuotasTableData);
        }
    }, [partner, setPartner]);

    const sumColumns = [
        {
            key: "date", 
            label: "Data", 
            sortable: true
        },
        {
            key: "quotas", 
            label: "Cotas", 
            sortable: true
        },
        {
            key: "credit", 
            label: "Crédito", 
            sortable: true
        },
        {
            key: "value", 
            label: "Entrada", 
            sortable: true
        },
    ]

    useEffect(() => {
        const newPartnerSumsData = partnerSums?.map((sum) => {
            return {
                date: sum.created_at ? formatBRDate(sum.created_at) : "",
                credit: sum.credit ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(sum.credit) : 0,
                entry: sum.entry ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(sum.entry) : 0,
                quotas: sum.cards.length,
            }
        });

        if(newPartnerSumsData){
            setPartnerSumsData(newPartnerSumsData);
        }
    }, [partnerSums, setPartnerSums]);

    console.log(partnerSums);

    return (
        <>  
            <MainBoard sidebar="quotas" header={
                partner && (
                    <HStack>
                        <Link href="/parceiros" w="fit-content"><BackArrow width="20px" stroke="#4e4b66" fill="none"/></Link>
                        <Text color="gray.800" ml="4" w="100%">
                            Parceiros / {partner.name}
                        </Text>
                    </HStack>
                )
            }>
                {
                    partner && (
                        <Stack spacing="8">
                            <ConfirmQuotaSaleCancelModal afterCancel={loadPartnerData} toCancelQuotaSaleData={cancelQuotaSaleData} isOpen={isConfirmQuotaSaleCancelModalOpen} onRequestClose={CloseConfirmQuotaSaleCancelModal}/>
                            <ConfirmQuotaSaleRemoveModal afterRemove={loadPartnerData} toRemoveQuotaSaleData={removeQuotaSaleData} isOpen={isConfirmQuotaSaleRemoveModalOpen} onRequestClose={CloseConfirmQuotaSaleRemoveModal}/>
                            
                            <HStack justifyContent="space-between">
                                <Heading fontSize="2xl">{partner.name}</Heading>
                            </HStack>

                            <Divider/>

                            <Stack spacing="6">
                                <Stack direction={["column", "column", "row", "row"]} justifyContent="space-between">
                                    <HStack>
                                        <Text fontSize="sm">Nome da empresa:</Text>
                                        <Text fontWeight="bold" fontSize="sm">{partner.name}</Text>
                                    </HStack>

                                    <HStack>
                                        <Text fontSize="sm">CNPJ:</Text>
                                        <Text fontWeight="bold" fontSize="sm">{partner.cnpj}</Text>
                                    </HStack>

                                    <HStack>
                                        <Text fontSize="sm">Email:</Text>
                                        <Text fontWeight="bold" fontSize="sm">{partner.email}</Text>
                                    </HStack>

                                    <HStack>
                                        <Text fontSize="sm">Telefone:</Text>
                                        <Text fontWeight="bold" fontSize="sm">{partner.phone}</Text>
                                    </HStack>
                                </Stack>

                                <Stack direction={["column", "column", "row", "row"]} justifyContent="space-between"> 
                                    <HStack>
                                        <Text fontSize="sm">Endereço:</Text>
                                        <Text fontWeight="bold" fontSize="sm">{partner.address}</Text>
                                    </HStack>

                                    <HStack>
                                        <Text fontSize="sm">Processos:</Text>
                                        <Text fontWeight="bold" fontSize="sm">{partner.finished_processes} / {partner.processes + partner.finished_processes}</Text>
                                    </HStack>
                                </Stack>
                            </Stack>

                            <HStack spacing="8" width="100%">
                                <Stack spacing="5" w="100%" minWidth="300px" justify="space-between" alignItems="left" bg="white" borderRadius="16px" shadow="xl" px="8" py="8">
                                    <Text fontSize="xl" w="100%">Cotas fornecidas:</Text>

                                    <Text fontSize="2xl" w="100%" fontWeight="bold" color={partner.bought_quotas_total > 0 ? "blue.400" : "red.400"}>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(partner.bought_quotas_total)}</Text>
                                    <Text fontSize="xl" w="100%" >Cotas fornecidas: <b>{partner.bought_quotas_count}</b></Text>

                                    <Link href="/contempladas" display="flex" alignItems="center" fontSize="md" color="gray.700"><CheckIcon width="20px" stroke="#6e7191" fill="none"/> <Text ml="2">Ver estoque</Text></Link>  
                                </Stack>

                                <Stack spacing="5" w="100%" minWidth="300px" justify="space-between" alignItems="left" bg="white" borderRadius="16px" shadow="xl" px="8" py="8">
                                    <Text fontSize="xl" w="100%">Vendas</Text>

                                    <Text fontSize="2xl" w="100%" fontWeight="bold" color={partner.sold_quotas_total > 0 ? "green.400" : "red.400"}>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(partner.sold_quotas_total)}</Text>
                                    <Text fontSize="xl" w="100%" >Cotas vendidas: <b>{partner.sold_quotas_count}</b></Text>

                                    <Link href="/venda-contempladas" display="flex" alignItems="center" fontSize="md" color="gray.700"><CheckIcon width="20px" stroke="#6e7191" fill="none"/> <Text ml="2">Ver vendas</Text></Link>  
                                </Stack>
                            </HStack>

                            <Accordion w="100%" border="2px" borderColor="gray.500" borderRadius="26" overflow="hidden" spacing="0" allowMultiple>
                                <HStack spacing="8" justify="space-between" paddingX="8" paddingY="3" bg="gray.200">
                                    <Text fontWeight="extrabold">{partner.bought_quotas_count} Compras</Text>
                                    
                                    {/* <Text float="right">TOTAL faturado: <strong>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(monthTotalValue)}</strong></Text> */}
                                    <Text float="right">TOTAL em crédito: <strong>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(partner.bought_quotas_total)}</strong></Text>
                                </HStack>

                                {
                                        partner.bought_quotas && partner.bought_quotas.map((quotaSale:QuotaSale) => {
                                            const creditAmount = (quotaSale.ready_quota ? quotaSale.ready_quota.credit : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + saleReadyQuota.ready_quota.credit}, 0) : 0)
                                            const totalCost = (quotaSale.ready_quota && quotaSale.ready_quota.total_cost ? quotaSale.ready_quota.total_cost : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + (saleReadyQuota.ready_quota.total_cost ? saleReadyQuota.ready_quota.total_cost : 0)}, 0) : 0)
                                            
                                            console.log(quotaSale.sale_ready_quotas[0].id);
                                            return(
                                                <AccordionItem key={quotaSale.id} display="flex" flexDir="column" paddingX="8" paddingTop="3" bg="white" borderTop="2px" borderTopColor="gray.500" borderBottom="0">
                                                    {({ isExpanded }) => (
                                                        <>
                                                            <HStack justify="space-between" mb="3" opacity={quotaSale.cancelled ? 0.5 : 1}>
                                                                <AccordionButton p="0" height="fit-content" w="auto">
                                                                    <Flex alignItems="center" justifyContent="center" h="24px" w="30px" p="0" borderRadius="full" border="2px" borderColor="blue.800" variant="outline">
                                                                    { 
                                                                            !isExpanded ? <StrongPlusIcon stroke="#2a4365" fill="none" width="12px"/> :
                                                                            <MinusIcon stroke="#2a4365" fill="none" width="12px"/>
                                                                    } 
                                                                    </Flex>
                                                                </AccordionButton>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">{quotaSale.sale_date ? formatBRDate(quotaSale.sale_date) : "--"}</Text>
                                                                    <Text fontSize="sm" fontWeight="bold" color="gray.800">{quotaSale.id}</Text>
                                                                </Stack>

                                                                <HStack spacing="4">
                                                                    <HomeIcon stroke="#4e4b66" fill="none" width="22px"/>

                                                                    <Stack spacing="0">
                                                                        <Text fontSize="10px" color="gray.800">Valor da venda</Text>
                                                                        <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(creditAmount)}</Text>
                                                                    </Stack>
                                                                </HStack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800" fontWeight="bold">Valor recebido</Text>
                                                                    <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.value)} ({((quotaSale.value*100)/creditAmount).toFixed(0)}%)</Text>
                                                                </Stack>

                                                                {
                                                                    quotaSale.ready_quota && (
                                                                        <Stack spacing="0">
                                                                            <Text fontSize="12px" color="gray.800">Grupo: <strong>{quotaSale.ready_quota.group}</strong></Text>
                                                                            <Text fontSize="12px" color="gray.800">Cota: <strong>{quotaSale.ready_quota.quota}</strong></Text>
                                                                        </Stack>
                                                                    )
                                                                }

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800" fontWeight="bold">Cotas</Text>
                                                                    <HStack>
                                                                    {
                                                                        (quotaSale.sale_ready_quotas.length > 0) && quotaSale.sale_ready_quotas.map((saleReadyQuota) => {
                                                                            return(
                                                                                <Text fontSize="sm" fontWeight="bold" color="gray.800">{saleReadyQuota.ready_quota.group}-{saleReadyQuota.ready_quota.quota},</Text>
                                                                            )
                                                                        })
                                                                    }
                                                                    </HStack>
                                                                </Stack>
                            
                                                                <HStack spacing="5">
                                                                    {
                                                                        quotaSale.cancelled && (
                                                                            <HStack>
                                                                                <Flex fontWeight="bold" alignItems="center" color="red.400">
                                                                                    <CloseIcon stroke="#c30052" fill="none" width="16px"/>
                                                                                    <Text ml="2">Cancelada</Text>
                                                                                </Flex>
                                                                            </HStack>
                                                                        )
                                                                    }

                                                                    {/* <EditButton disabled={!!quotaSale.cancelled} onClick={() => history.push(`editar-venda/${quotaSale.id}`)}/> */}
                                                                    <EditButton disabled={true} onClick={() => history.push(`editar-venda/${quotaSale.id}`)}/>
                                                                </HStack>
                                                            </HStack>
                            
                                                            <AccordionPanel flexDir="column" borderTop="2px" borderColor="gray.500" px="0" py="5" opacity={quotaSale.cancelled ? 0.5 : 1}>
                                                                <HStack justifyContent="space-between" marginBottom="4">
                                                                    <Stack spacing="0">
                                                                        <Text fontSize="10px" color="gray.800">Lucro</Text>
                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.profit - (totalCost ? totalCost : 0))}</Text>
                                                                    </Stack>

                                                                    <Stack spacing="0">
                                                                        <Text fontSize="10px" color="gray.800">Taxa</Text>
                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.tax ? quotaSale.tax : 0)}</Text>
                                                                    </Stack>

                                                                    <Stack spacing="0">
                                                                        <Text fontSize="10px" color="gray.800">Vendido por</Text>
                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.salesman?.name}</Text>
                                                                    </Stack>

                                                                    <Stack spacing="0">
                                                                        <Text fontSize="10px" color="gray.800">Comprado por</Text>
                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.buyer?.name}</Text>
                                                                    </Stack>
                                                                </HStack>

                                                                <HStack justifyContent="space-between" mb="4">
                                                                    <Stack spacing="0">
                                                                        <Text fontSize="10px" color="gray.800">Comissão do vendedor</Text>
                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.supervisor_value ? quotaSale.supervisor_value : 0)}</Text>
                                                                    </Stack>
                                                                </HStack>

                                                                <HStack mt="4" mb="3" justifyContent="space-between">
                                                                    <Flex alignItems="center">
                                                                        <Text fontWeight="500" mr="2">Descrição: </Text>
                                                                        <Text> {quotaSale.description && quotaSale.description}</Text>
                                                                    </Flex>

                                                                    <HStack spacing="5" alignItems="center">
                                                                        <SolidButton disabled={!!quotaSale.cancelled} colorScheme="red" h="29px" pl="5" pr="5" bg="red.400" minWidth="none" onClick={() => OpenConfirmQuotaSaleCancelModal({ id: quotaSale.id}) }>
                                                                            Cancelar Venda
                                                                        </SolidButton>
                                                                        <RemoveButton onClick={() => OpenConfirmQuotaSaleRemoveModal({ id: quotaSale.id }) }/>
                                                                    </HStack>
                                                                </HStack>

                                                                <Stack pb="6" pt="3">
                                                                    <HStack>
                                                                        <Text fontWeight="700" mr="2">Cotas vendidas: </Text>
                                                                    </HStack>
                                                                    {
                                                                        quotaSale.sale_ready_quotas.map((saleReadyQuota) => {
                                                                            return(
                                                                                <Stack spacing="2" padding="3" bg="gray.100" borderRadius="16px">
                                                                                    <HStack justifyContent={"space-between"} key={saleReadyQuota.id}>
                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Cota</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{saleReadyQuota.ready_quota.group} - {saleReadyQuota.ready_quota.quota}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Valor</Text>
                                                                                            <Text fontSize="12px" fontWeight="semibold" color="gray.600">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.value ? saleReadyQuota.ready_quota.value : 0)}</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.value)}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Crédito</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.credit ? saleReadyQuota.ready_quota.credit : 0)}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="12px" color="gray.800">{saleReadyQuota.ready_quota.deadline ? `${saleReadyQuota.ready_quota.deadline}x` : "--"}</Text>
                                                                                            <Text fontSize="sm" fontWeight="normal" color="gray.800">{saleReadyQuota.ready_quota.parcel ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.parcel) : ""}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Custo</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.total_cost ? saleReadyQuota.ready_quota.total_cost : 0)}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Lucro</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.profit ? saleReadyQuota.profit : 0)}</Text>
                                                                                        </Stack>
                                                                                    </HStack>

                                                                                    <HStack justifyContent={"space-between"} key={saleReadyQuota.id}>
                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Comissão do coordenador</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.coordinator_commission ? saleReadyQuota.ready_quota.coordinator_commission : 0)}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Comissão do supervisor</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format((saleReadyQuota.ready_quota.supervisor_commission ? saleReadyQuota.ready_quota.supervisor_commission : 0))}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Custo Parceiro</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.partner_cost ? saleReadyQuota.ready_quota.partner_cost : 0)}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Lucro Parceiro</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (saleReadyQuota.partner_profit) - (saleReadyQuota.ready_quota.partner_cost ? saleReadyQuota.ready_quota.partner_cost : 0))}</Text>
                                                                                        </Stack>

                                                                                        <Stack spacing="0">
                                                                                            <Text fontSize="10px" color="gray.800">Ganho do Investidor</Text>
                                                                                            <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (saleReadyQuota.investor_value))}</Text>
                                                                                        </Stack>
                                                                                    </HStack>
                                                                                </Stack>
                                                                            )
                                                                        })
                                                                    }
                                                                </Stack>

                                                                <Divider mb="3"/>

                                                                <HStack justifyContent="space-between" alignItems="center">
                                                                    <Table size="sm" variant="simple">
                                                                        <Thead>
                                                                            <Tr>
                                                                                <Th color="gray.900">Valores a receber: </Th>
                                                                                <Th></Th>
                                                                                <Th></Th>
                                                                                <Th></Th>
                                                                            </Tr>
                                                                        </Thead>
                                                                        <Tbody>
                                                                            {
                                                                                quotaSale.bills && quotaSale.bills.map((bill) => {
                                                                                    const billToEditData:EditBillFormData = {
                                                                                        id: bill.id,
                                                                                        title: bill.title,
                                                                                        value: bill.value.toString().replace('.', ','),
                                                                                        paid: bill.paid.toString().replace('.', ','),
                                                                                        company: bill.company,
                                                                                        category: bill.category,
                                                                                        source: bill.source,
                                                                                        status: bill.status,
                                                                                        expire: bill.expire,
                                                                                        observation: bill.observation,
                                                                                    }

                                                                                    return (
                                                                                        <Tr>
                                                                                            <Td fontSize="12px">{bill.expire && formatBRDate(bill.expire)}</Td>
                                                                                            <Td fontSize="12px">{bill.title}</Td>
                                                                                            <Td color="gray.800" fontWeight="semibold" fontSize="12px">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(bill.status ? (bill.paid > 0 ? bill.paid : bill.value) : bill.value - bill.paid)}</Td>
                                                                                            <Td color="gray.800" fontWeight="semibold" fontSize="12px">
                                                                                                {
                                                                                                    bill.status ? (
                                                                                                        <HStack>
                                                                                                            <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                                                                <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                                                                <Text ml="2">Recebido</Text>
                                                                                                            </Flex>

                                                                                                            <IconButton onClick={() => handleReverseBill(bill.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                                                        </HStack>
                                                                                                    ) : (
                                                                                                        <OutlineButton disabled={!!quotaSale.cancelled}
                                                                                                            h="29px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11" 
                                                                                                            onClick={() => OpenReceiveBillModal({ id: bill.id, title: bill.title , value: bill.value, paid: bill.paid, status: bill.status, new_value: ''})}>
                                                                                                            Receber
                                                                                                        </OutlineButton>
                                                                                                    )
                                                                                                }
                                                                                            </Td>
                                                                                            <Td>
                                                                                                <IconButton onClick={() => OpenConfirmBillRemoveModal({ id: bill.id, title: bill.title }) } h="24px" w="23px" p="0" float="right" aria-label="Excluir pagamento parcial" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                                                                                                <IconButton onClick={() => OpenEditBillModal(billToEditData)} h="24px" w="23px" p="0" float="right" aria-label="Alterar parcial" border="none" icon={ <EditIcon width="20px" stroke="#d69e2e" fill="none"/>} variant="outline"/>
                                                                                            </Td>
                                                                                        </Tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Tbody>
                                                                    </Table>
                                                                </HStack>

                                                                <Divider />

                                                                <HStack justifyContent="space-between" alignItems="center" mt="4">
                                                                    <Table size="sm" variant="simple">
                                                                        <Thead>
                                                                            <Tr>
                                                                                <Th color="gray.900">Valores a apagar: </Th>
                                                                                <Th></Th>
                                                                                <Th></Th>
                                                                                <Th></Th>
                                                                            </Tr>
                                                                        </Thead>
                                                                        <Tbody>
                                                                            
                                                                            {
                                                                                quotaSale.payments && quotaSale.payments.map((payment) => {
                                                                                    const paymentToEditData:EditPaymentFormData = {
                                                                                        id: payment.id,
                                                                                        title: payment.title,
                                                                                        value: payment.value.toString().replace('.', ','),
                                                                                        paid: payment.paid.toString().replace('.', ','),
                                                                                        company: payment.company,
                                                                                        category: payment.category,
                                                                                        provider: payment.provider,
                                                                                        pay_to_user: payment.pay_to_user?.id,
                                                                                        //status: payment.status,
                                                                                        expire: payment.expire,
                                                                                        observation: payment.observation,
                                                                                        contract: payment.contract,
                                                                                        group: payment.group,
                                                                                        quote: payment.quote,
                                                                                        recurrence: payment.recurrence,
                                                                                        file: payment.file,
                                                                                    }

                                                                                    console.log(paymentToEditData);

                                                                                    return (
                                                                                        <Tr>
                                                                                            <Td fontSize="12px">{payment.expire && formatBRDate(payment.expire)}</Td>
                                                                                            <Td fontSize="12px">{payment.title}</Td>
                                                                                            <Td color="gray.800" fontWeight="semibold" fontSize="12px">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(payment.status ? (payment.paid > 0 ? payment.paid : payment.value) : payment.value - payment.paid)}</Td>
                                                                                            <Td color="gray.800" fontWeight="semibold" fontSize="12px">
                                                                                                {
                                                                                                    payment.status ? (
                                                                                                        <HStack>
                                                                                                            <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                                                                <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                                                                <Text ml="2">Pago</Text>
                                                                                                            </Flex>

                                                                                                            <IconButton onClick={() => handleReverseBill(payment.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                                                        </HStack>
                                                                                                    ) : (
                                                                                                        <OutlineButton disabled={!!quotaSale.cancelled}
                                                                                                            h="29px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11" 
                                                                                                            onClick={() => OpenPayPaymentModal({ id: payment.id, title: payment.title , value: payment.value, paid: payment.paid, status: payment.status, new_value: ''})}>
                                                                                                            Pagar
                                                                                                        </OutlineButton>
                                                                                                    )
                                                                                                }
                                                                                            </Td>
                                                                                            <Td>
                                                                                                <IconButton onClick={() => OpenConfirmPaymentRemoveModal({ id: payment.id, title: payment.title }) } h="24px" w="23px" p="0" float="right" aria-label="Excluir pagamento parcial" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                                                                                                <IconButton onClick={() => OpenEditPaymentModal(paymentToEditData)} h="24px" w="23px" p="0" float="right" aria-label="Alterar parcial" border="none" icon={ <EditIcon width="20px" stroke="#d69e2e" fill="none"/>} variant="outline"/>
                                                                                            </Td>
                                                                                        </Tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Tbody>
                                                                    </Table>
                                                                </HStack>
                            
                                                            </AccordionPanel>
                                                        </>
                                                    )}
                                                </AccordionItem>
                                            )
                                        })
                                }
                                
                            </Accordion>

                            <HStack spacing="8" width="100%">
                                <Stack spacing="5" w="100%" minWidth="300px" justify="space-between" alignItems="left" bg="white" borderRadius="16px" shadow="xl" px="8" py="8">
                                    <Text fontSize="xl" w="100%">Últimas cotas fornecidas</Text>
                                    <SortableTable columns={quotaColumns} data={soldQuotasTableData} size="small"/>
                                </Stack>

                                <Stack spacing="5" w="100%" minWidth="300px" justify="space-between" alignItems="left" bg="white" borderRadius="16px" shadow="xl" px="8" py="8">
                                    <HStack>
                                        <Text fontSize="xl" w="100%">Somas recentes</Text>
                                        <Badge colorScheme="gray" bg={"gray.200"} color="gray.800">
                                            Total: {partnerSums?.length}
                                        </Badge>
                                    </HStack>
                                    <SortableTable columns={sumColumns} data={partnerSumsData} size="small" />
                                </Stack>
                            </HStack>


                            {/* <SolidButton onClick={OpenNewBranchModal} mb="12" color="white" bg="purple.300" icon={PlusIcon} colorScheme="purple">
                                Adicionar Filial
                            </SolidButton> */}
                        </Stack>
                    )
                }
            </MainBoard>
        </>
    )
}