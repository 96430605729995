import {
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
  Input as ChakraInput,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Checkbox,
  Spinner
} from '@chakra-ui/react'
import { SolidButton } from '../../../../components/Buttons/SolidButton'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../../../services/api'
import { useHistory } from 'react-router'
import { useErrors } from '../../../../hooks/useErrors'

import { Input } from '../../../../components/Forms/Inputs/Input'
import { Select } from '../../../../components/Forms/Selects/Select'
import { useWorkingCompany } from '../../../../hooks/useWorkingCompany'
import { formatInputDate } from '../../../../utils/Date/formatInputDate'
import moneyToBackend from '../../../../utils/moneyToBackend'
import { HasPermission, useProfile } from '../../../../hooks/useProfile'
import { useEffect, useState } from 'react'
import { isAuthenticated } from '../../../../services/auth'
import { redirectMessages } from '../../../../utils/redirectMessages'
import { ReactSelect, SelectOption } from '../../../../components/Forms/ReactSelect'
import { LeadsFilterData, useLeads } from '../../../../hooks/useLeads'
import { City, Customer, Lead, State, User } from '../../../../types'
import { useStates } from '../../../../hooks/useStates'
import { useCities } from '../../../../hooks/useCities'
import { useWorkingBranch } from '../../../../hooks/useWorkingBranch'

interface NewProcessModalProps {
  isOpen: boolean
  onRequestClose: () => void
  toAddProcessPartnerData?: AddProcessPartnerData;
  afterCreate?: () => void
}

export interface AddProcessPartnerData {
  id: number
  name: string
}

export interface CreateNewProcessFormData {
    partner_id?: number;
    group: string;
    quota: string;
    client_name: string;
  }

const CreateNewProcessFormSchema = yup.object().shape({
  group: yup.string().required('Nome do cliente obrigatório'),
  quota: yup.string().required('Nome do cliente obrigatório'),
  client_name: yup.string().required('Nome do cliente obrigatório'),
})

export function NewProcessModal({
  isOpen,
  onRequestClose,
  afterCreate,
  toAddProcessPartnerData
}: NewProcessModalProps) {
  const workingCompany = useWorkingCompany();
  const workingBranch = useWorkingBranch();
  const { profile, permissions } = useProfile()

  const history = useHistory()
  const toast = useToast()
  const { showErrors } = useErrors()

  const { register, handleSubmit, control, reset, watch, formState } =
    useForm<CreateNewProcessFormData>({
      resolver: yupResolver(CreateNewProcessFormSchema)
    })

  const handleCreateNewPayment = async (processData: CreateNewProcessFormData) => {
    try {
      if (!workingCompany.company) {
        toast({
          title: 'Ops',
          description: `Seleciona uma empresa para trabalhar`,
          status: 'warning',
          duration: 12000,
          isClosable: true
        })

        return
      }

      if (!profile) {
        return
      }

      //const isManager = HasPermission(permissions, 'Vendas Completo');

      const newProcessData = {
        client_name: processData.client_name,
        group: processData.group,
        quota: processData.quota,
        partner_id: toAddProcessPartnerData?.id
      };

      const response = await api.post('/processes', newProcessData)

      toast({
        title: 'Sucesso',
        description: `Processo cadastrado`,
        status: 'success',
        duration: 12000,
        isClosable: true
      })

      await api.post('/logs/store', {
        user: profile.id,
        company: workingCompany.company.id,
        action: `Cadastrou um novo processo`
      })

      onRequestClose()
      if(afterCreate){
        afterCreate();
      }
      reset()
    } catch (error: any) {
      showErrors(error, toast)

      if (error.response.data.access) {
        history.push('/')
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push({
        pathname: '/',
        state: redirectMessages.auth
      })
    }
  }, [isOpen]);

  console.log(toAddProcessPartnerData);

  return (
    <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
      <ModalOverlay />
      <ModalContent
        maxH="calc(100vh - 120px)"
        overflow="auto"
        as="form"
        borderRadius="24px"
        onSubmit={handleSubmit(handleCreateNewPayment)}
      >
        <ModalHeader p="10" fontWeight="700" fontSize="2xl">
          Cadastrar novo processo
          {
            toAddProcessPartnerData && (
              <Text fontSize="md" fontWeight="normal" color="gray.700">Adicionar processo ao parceiro <b>{toAddProcessPartnerData.name}</b></Text>
            )
          }
        </ModalHeader>

        <ModalCloseButton top="10" right="5" />

        <ModalBody pl="10" pr="10">
          <Stack spacing="6">
            

            {/* <HStack spacing="4" align="baseline">
                <Input register={register} name="contract" type="text" placeholder="Contrato" focusBorderColor="blue.800" variant="outline" error={formState.errors.contract}/>

                <Input register={register} name="group" type="text" placeholder="Grupo" focusBorderColor="blue.800" variant="outline" error={formState.errors.group}/>

                <Input register={register} name="quota" type="text" placeholder="Cota" focusBorderColor="blue.800" variant="outline" mask="" error={formState.errors.quota}/>
            </HStack> */}
            {
              toAddProcessPartnerData && (
                <>

                  <Input
                      register={register}
                      name="client_name"
                      type="text"
                      placeholder="Nome do cliente"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.client_name}
                    />

                  <HStack spacing="4" alignItems="flex-start">
                    <Input
                      register={register}
                      name="group"
                      type="text"
                      placeholder="Grupo"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.group}
                    />

                    <Input
                      register={register}
                      name="quota"
                      type="text"
                      placeholder="Cota"
                      focusBorderColor="blue.800"
                      variant="outline"
                      error={formState.errors.quota}
                    />
                  </HStack>
                </>
              )
            }
            
          </Stack>
        </ModalBody>

        <ModalFooter p="10">
          <SolidButton
            mr="6"
            color="white"
            bg="blue.800"
            colorScheme="blue"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            Cadastrar
          </SolidButton>

          <Link onClick={onRequestClose} color="gray.700" fontSize="14px">
            Cancelar
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
