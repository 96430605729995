import { FormControl, Flex, HStack, Stack, Spinner, Text, IconButton, Button, Select as ChakraSelect, Box, useBreakpointValue, Icon, Accordion, AccordionItem, AccordionPanel, Table, Thead, Tr, Th, Tbody, Td, AccordionButton, Link} from "@chakra-ui/react";
import { SolidButton } from "../../../components/Buttons/SolidButton";
import { MainBoard } from "../../../components/MainBoard";
import { useCompanies } from "../../../hooks/useCompanies";
import { HasPermission, useProfile } from "../../../hooks/useProfile";
import { BillCategory, CashFlowCategory, CashFlowInterface, Company, Partner, QuotaProcess } from "../../../types";

import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactComponent as PlusIcon } from '../../../assets/icons/Plus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { ReactComponent as TagIcon } from '../../../assets/icons/Tag.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/Edit.svg';

import { Input } from "../../../components/Forms/Inputs/Input";
import { OutlineButton } from "../../../components/Buttons/OutlineButton";
import { EditButton } from "../../../components/Buttons/EditButton";
import { CompanySelect } from "../../../components/CompanySelect";
import React, { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { useWorkingCompany } from "../../../hooks/useWorkingCompany";
import { formatDate } from "../../../utils/Date/formatDate";
import { formatYmdDate } from "../../../utils/Date/formatYmdDate";
import { formatBRDate } from "../../../utils/Date/formatBRDate";
import { getDay } from "../../../utils/Date/getDay";
import { useCashFlows, CashFlowsFilterData } from "../../../hooks/useCashFlows";
import { getHour } from "../../../utils/Date/getHour";
import { EditPartnerFormData, EditPartnerModal } from "./EditPartnerModal";
import { Select } from "../../../components/Forms/Selects/Select";
import { Pagination } from "../../../components/Pagination";
import { ExportReportModal } from "./ExportReportModal";
import { CompanySelectMaster } from "../../../components/CompanySelect/companySelectMaster";
import { useWorkingBranch } from "../../../hooks/useWorkingBranch";
import { usePartners } from "../../../hooks/usePartners";
import { AddProcessPartnerData, NewProcessModal } from "./Processes/NewProcessModal";
import { RemoveButton } from "../../../components/Buttons/RemoveButton";
import { ConfirmProcessRemoveModal, RemoveProcessData } from "./Processes/ConfirmProcessRemoveModal";
import { ReactComponent as MinusIcon } from '../../../assets/icons/Minus.svg';
import { ReactComponent as StrongPlusIcon } from '../../../assets/icons/StrongPlus.svg';
import { EditProcessFormData, EditProcessModal } from "./Processes/EditProcessModal";

const FilterCashFlowFormSchema = yup.object().shape({
    search: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    category: yup.string(),
});

export default function Partners(){
    const workingCompany = useWorkingCompany();
    const workingBranch = useWorkingBranch();
    const isWideVersion = useBreakpointValue({base: false, lg: true});

    const [filter, setFilter] = useState<CashFlowsFilterData>(() => {
        const data: CashFlowsFilterData = {
            search: '',
            company: workingCompany.company?.id,
            branch: workingBranch.branch?.id
        };
        
        return data;
    })

    const [page, setPage] = useState(1);

    const partners = usePartners(filter, 50, page);

    const {profile, permissions} = useProfile();
    const companies = useCompanies();

    const { register, handleSubmit, formState} = useForm<CashFlowsFilterData>({
        resolver: yupResolver(FilterCashFlowFormSchema),
    });

    function handleChangeCompany(event:any){
        const selectedCompanyId = (event?.target.value ? event?.target.value : 1);
        const selectedCompanyData = companies.data.filter((company:Company) => company.id === selectedCompanyId)[0]
        workingCompany.changeCompany(selectedCompanyData);

        const updatedFilter = filter;
        updatedFilter.company = selectedCompanyId;

        setFilter(updatedFilter);
    }

    function handleChangeFilter(newFilter: CashFlowsFilterData){
        setFilter(newFilter);
    }

    // useEffect(() => {
    //     console.log(filter);
    //     cashFlows.refetch();
    // }, [filter])

    const [isNewProcessModalOpen, setIsNewProcessModalOpen] = useState(false);
    const [toAddProcessPartnerData, setToAddProcessPartnerData] = useState<AddProcessPartnerData>(() => {
        const data: AddProcessPartnerData = {
            id: 0,
            name: '',
        };
        
        return data;
    });

    function OpenNewPartnerModal(toAddPartnerData: AddProcessPartnerData){
        setToAddProcessPartnerData(toAddPartnerData);
        setIsNewProcessModalOpen(true);
    }
    function CloseNewPartnerModal(){
        setIsNewProcessModalOpen(false);
    }

    const [toEditPartnerData, setToEditPartnerData] = useState<EditPartnerFormData>(() => {

        const data: EditPartnerFormData = {
            id: 0,
            name: '',
            slug: '',
            name_display: '',
            email: '',
            phone: '',
            email_display: '',
            cnpj: '',
            address: '',
            logo: '',
            color: '',
            second_color: '',
        };
        
        return data;
    });

    const [toEditProcessData, setToEditProcessData] = useState<EditProcessFormData>(() => {

        const data: EditProcessFormData = {
            id:0,
            client_name:"",
            partner_id:0,
            finished:false,
            group:"",
            quota:"",
        };
        
        return data;
    });
    const [isEditProcessModalOpen, setIsEditProcessModalOpen] = useState(false);

    function OpenEditProcessModal(process : EditProcessFormData){
        setToEditProcessData(process);
        setIsEditProcessModalOpen(true);
    }
    function CloseEditProcessModal(){
        setIsEditProcessModalOpen(false);
    }

    const [isConfirmProcessRemoveModalOpen, setisConfirmProcessRemoveModalOpen] = useState(false);
    const [removeProcessData, setRemoveProcessData] = useState<RemoveProcessData>(() => {

        const data: RemoveProcessData = {
            group: '',
            quota: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmProcessRemoveModal(processData: RemoveProcessData){
        setRemoveProcessData(processData);
        setisConfirmProcessRemoveModalOpen(true);
    }
    function CloseConfirmProcessRemoveModal(){
        setisConfirmProcessRemoveModalOpen(false);
    }

    const [isExportReportModalOpen, setIsExportReportModalOpen] = useState(false);

    const handleSearchPartners = async (search : CashFlowsFilterData) => {
        setFilter({...filter, ...search});
    }

    const [toggleFilter, setToggleFilter] = useState(false);

    useEffect(() => {
        setFilter({...filter, company: workingCompany.company?.id, branch: workingBranch.branch?.id});
    }, [workingCompany, workingBranch]);

    return(
        <MainBoard sidebar="quotas" header={<CompanySelectMaster />}
        >
            <NewProcessModal afterCreate={partners.refetch} toAddProcessPartnerData={toAddProcessPartnerData} isOpen={isNewProcessModalOpen} onRequestClose={CloseNewPartnerModal}/>
            <EditProcessModal  toEditProcessData={toEditProcessData} afterEdit={partners.refetch} isOpen={isEditProcessModalOpen} onRequestClose={CloseEditProcessModal}/>
            <ConfirmProcessRemoveModal afterRemove={partners.refetch} toRemoveProcessData={removeProcessData} isOpen={isConfirmProcessRemoveModalOpen} onRequestClose={CloseConfirmProcessRemoveModal}/>

            <HStack justify="space-between" alignItems="center" mb="10">
                {/* <SolidButton onClick={OpenNewProcessModal} color="white" bg="blue.400" icon={PlusIcon} colorScheme="blue">
                    Adicionar Movimentação
                </SolidButton> */}

                {/* <OutlineButton onClick={OpenExportReportModal} variant="outline" colorScheme="blue" color="blue.400" borderColor="blue.400">
                    Gerar Relatório
                </OutlineButton> */}

                {/* <Link href="/categorias" border="2px" borderRadius="full" borderColor="gray.500" px="6" h="8" alignItems="center">
                    <Text>Categorias</Text>
                </Link> */}
            </HStack>

            <Stack flexDir={["column", "row"]} spacing="6" as="form" mb="20" onSubmit={handleSubmit(handleSearchPartners)} borderRadius={!isWideVersion ? "24" : ""}  p={!isWideVersion ? "5" : ""} bg={!isWideVersion ? "white" : ""} boxShadow={!isWideVersion ? "md" : ""}>
                {
                    !isWideVersion && (
                        <HStack onClick={() => setToggleFilter(!toggleFilter)}>
                            <Icon as={PlusIcon} fontSize="20" stroke={"gray.800"} />
                            <Text>Filtrar pagamentos</Text>
                        </HStack>
                    )
                }

                <Box display={(isWideVersion || (!isWideVersion && toggleFilter)) ? 'flex' : 'none'}>

                    <Stack spacing="6" w="100%">
                        <Input register={register} name="search" type="text" placeholder="Procurar" variant="filled" focusBorderColor="blue.800" error={formState.errors.search}/>

                        <Stack direction={["column", "row"]} spacing="6">
                            <Input register={register} name="start_date" type="date" placeholder="Data inicial" focusBorderColor="blue.800" variant="filled" error={formState.errors.start_date}/>
                            <Input register={register} name="end_date" type="date" placeholder="Data Final" focusBorderColor="blue.800" variant="filled" error={formState.errors.end_date}/>

                            {/* <Select register={register} h="45px" name="category" w="100%" maxW="200px" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="filled" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" placeholder="Categoria" error={formState.errors.category}>
                                {categories && categories.map((category:CashFlowCategory) => {
                                    return (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    )
                                })}
                            </Select> */}

                            <OutlineButton type="submit" mb="10" color="blue.800" borderColor="blue.800" colorScheme="blue">
                                Filtrar
                            </OutlineButton>

                        </Stack>
                    </Stack>

                </Box>

            </Stack>

            <Stack fontSize="13px" spacing="12">
                {   partners.isLoading ? (
                        <Flex justify="center">
                            <Spinner/>
                        </Flex>
                    ) : ( partners.isError ? (
                        <Flex justify="center" mt="4" mb="4">
                            <Text>Erro listar os parceiros</Text>
                        </Flex>
                    ) : (partners.data?.data.data.length === 0) && (
                        <Flex justify="center">
                            <Text>Nenhum parceiro encontrado.</Text>
                        </Flex>
                    ) ) 
                }
                {
                    (!partners.isLoading && !partners.error) && (
                        <Accordion w="100%" border="2px" borderColor="gray.500" borderRadius="26" overflow="hidden" spacing="0" allowMultiple>
                            <Stack w="100%" border="2px" borderColor="gray.500" borderRadius="26" overflow="hidden" spacing="0">
                                <HStack spacing="8" w="100%" justify="space-between" paddingX="8" paddingY="3" bg="gray.200">
                                    {/* <Text fontWeight="bold">{(todayFormatedDate === dayCashFlowsFormated) ? 'Hoje' : (tomorrow === cashFlowDay) ? "Amanhã" : ""} {formatBRDate(day)}</Text> */}
                                    <Text fontWeight="bold">Listando {partners.data?.data.data.length} parceiros</Text>

                                    <Flex alignItems="center" float="right" color={0 > 0 ? 'green.400' : 'red.400'}>
                                        {/* {totalDayAmount > 0 
                                            ? <StrongPlusIcon stroke="#48bb78" fill="none" width="12px"/> 
                                            : <MinusIcon stroke="#c30052" fill="none" width="12px"/>
                                        } */}
                                        <Text fontWeight="bold" ml="2">
                                            {/* {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(0)} */}
                                        </Text>
                                    </Flex>
                                </HStack>

                                {
                                    partners.data?.data.data.map((partner:Partner) => {
                                        const partnerToEditData:EditPartnerFormData = {
                                            id: partner.id,
                                            name: partner.name,
                                            slug: partner.slug,
                                            name_display: partner.name_display,
                                            email: partner.email,
                                            phone: partner.phone,
                                            email_display: partner.email_display,
                                            cnpj: partner.cnpj,
                                            address: partner.address,
                                            logo: partner.logo,
                                            color: partner.color,
                                            second_color: partner.second_color,
                                        }

                                        return (
                                            <AccordionItem key={partner.id} display="flex" flexDir="column" paddingX={["4", "8"]} paddingTop="3" bg="white" borderTop="2px" borderTopColor="gray.500" borderBottom="0">
                                                {({ isExpanded }) => (
                                                    <>
                                                        <Stack direction={["column", "row"]} px={["0","0"]} key={`${partner.id}-${partner.name}`} justifyContent="space-between" borderColor="gray.500" pt="2" pb="4">
                                                            <AccordionButton p="0" height="fit-content" w="auto">
                                                                <Flex alignItems="center" justifyContent="center" h={["20px", "24px"]} w={["24px", "30px"]} p="0" borderRadius="full" border="2px" borderColor="blue.400" variant="outline">
                                                                { 
                                                                        !isExpanded ? <StrongPlusIcon stroke="#2097ed" fill="none" width="12px"/> :
                                                                        <MinusIcon stroke="#2097ed" fill="none" width="12px"/>
                                                                } 
                                                                </Flex>
                                                            </AccordionButton>
                                                            <Stack direction={["row", "row"]} spacing={["3", "6"]} alignItems="center" mt={["1", "0"]}>
                                                                <Flex>
                                                                    <Text color="gray.800">{partner.name}</Text>
                                                                </Flex> 

                                                                <HStack>
                                                                    <Text fontWeight="" color="gray.800">
                                                                        {partner.cnpj}
                                                                    </Text>
                                                                </HStack>
                                                            </Stack>

                                                            <HStack>
                                                                <Text fontWeight="bold" color="gray.800">{Math.round((partner.sold_quotas_count / 3) - partner.processes)} processos disponíveis</Text>
                                                                {
                                                                    Math.round((partner.sold_quotas_count / 3) - partner.processes) >= 0 && (
                                                                        <IconButton border="1px solid" borderColor="gray.200" onClick={() => OpenNewPartnerModal({id: partner.id, name: partner.name})} h="26px" w="22px" minW="25px" p="0" float="right" aria-label="Adicionar processo" icon={ <PlusIcon width="16px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                    ) 
                                                                }
                                                            </HStack>

                                                            <Flex>
                                                                <Text fontWeight="bold" color="gray.800">{partner.finished_processes}/{partner.processes} processos em andamento</Text>
                                                            </Flex>

                                                            <HStack justifyContent="space-between" fontWeight="bold" spacing="7">
                                                                <Link href={`/parceiros/${partner.id}`}>
                                                                    <Button height="7" colorScheme="blue" color="blue.700" borderColor="blue.700" pl="4" pr="4" borderRadius="full" fontWeight="600" variant="outline" border="2px" fontSize="12px">
                                                                        Ver mais
                                                                    </Button>
                                                                </Link>

                                                                {/* <EditButton onClick={() => OpenEditProcessModal(cashFlowToEditData)}/>

                                                                <IconButton onClick={() => OpenConfirmProcessRemoveModal({ id: cashFlow.id, title: cashFlow.title })} h="24px" w="23px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/> */}
                                                            </HStack>
                                                        </Stack>

                                                        <AccordionPanel flexDir="column" borderTop="2px" borderColor="gray.500" px="0" py="5" fontSize={["11px", "small"]}>
                                                            <Stack direction={['column', 'column']} spacing="6" justifyContent="space-between" alignItems="center">
                                                                <HStack w="100%">
                                                                    <Text>Processos:</Text>
                                                                </HStack>
                                                                <Table size="sm" variant="simple">
                                                                    <Thead>
                                                                        <Tr>
                                                                            <Th color="gray.900">Data </Th>
                                                                            <Th color="gray.900">Cliente </Th>
                                                                            <Th color="gray.900">Grupo </Th>
                                                                            <Th color="gray.900">Cota </Th>
                                                                            <Th color="gray.900">Status </Th>
                                                                            <Th color="gray.900">Ações </Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                        
                                                                        {
                                                                            partner.quota_processes && partner.quota_processes.map((process: QuotaProcess) => {
                                                                                return (
                                                                                    <Tr>
                                                                                        <Td fontSize="12px">{process.created_at && formatBRDate(process.created_at.toString())}</Td>
                                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{process.client_name}</Td>
                                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{process.group}</Td>
                                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{process.quota}</Td>
                                                                                        <Td color={process.finished ? "green.400" : "yellow.400"} fontWeight="semibold" fontSize="12px">{process.finished ? "Finalizado" : "Em andamento"}</Td>
                                                                                        <Td>
                                                                                            <IconButton onClick={() => OpenConfirmProcessRemoveModal({id: process.id, group: process.group, quota: process.quota})} h="24px" w="23px" p="0" aria-label="Excluir pagamento parcial" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                                                                                            <IconButton onClick={() => OpenEditProcessModal({id: process.id, client_name: process.client_name, group: process.group, quota: process.quota, partner_id: process.partner_id, finished: process.finished})} h="24px" w="23px" p="0" float="right" aria-label="Alterar parcial" border="none" icon={ <EditIcon width="20px" stroke="#d69e2e" fill="none"/>} variant="outline"/>
                                                                                        </Td>
                                                                                    </Tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tbody>

                                                                    {/* <HStack>
                                                                        <Text>Valores pagos: </Text>
                                                                        <strong> {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(payment.paid)}</strong>
                                                                    </HStack>

                                                                    {
                                                                        payment.partial_payments && payment.partial_payments.map((partial: PartialPayment) => {
                                                                            return (
                                                                                <HStack>
                                                                                    <Text>{partial.pay_date && formatBRDate(partial.pay_date.toString())}</Text>
                                                                                    <Text color="gray.800" fontWeight="semibold"> {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(partial.value)}</Text>
                                                                                </HStack>
                                                                            )
                                                                        })
                                                                    } */}
                                                                </Table>
                                                                

                                                                {/* <HStack spacing="5" alignItems="center">
                                                                    <EditButton onClick={() => OpenEditPaymentModal(paymentToEditData)}/>
                                                                    <RemoveButton onClick={() => OpenConfirmPaymentRemoveModal({ id: payment.id, title: payment.title }) }/>
                                                                </HStack> */}
                                                            </Stack>

                                                        </AccordionPanel>
                                                    </>
                                                )}
                                            </AccordionItem>
                                        )
                                    })
                                }

                                
                            </Stack>
                        </Accordion>
                    )
                }
                <Pagination totalCountOfRegister={partners?.data?.data ? partners?.data?.data?.total_records : 0} registerPerPage={50} currentPage={page} onPageChange={setPage}/>
            </Stack>
            
        </MainBoard>
    );
}