import { Stack, Text, useToast, Link, HStack } from "@chakra-ui/react";
import { Partner, PartnerExternal, QuotaSale, ReadyQuota, SaleReadyQuota } from "../../../types";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { UserFilterData, useUsers } from "../../../hooks/useUsers";
import { useProviders } from "../../../hooks/useProviders";
import { useSources } from "../../../hooks/useSources";
import { ReactComponent as CheckIcon } from '../../../assets/icons/Check.svg';
import SortableTable, { SortableTableData } from "../../../components/SortableTable";
import { formatYmdDate } from "../../../utils/Date/formatYmdDate";
import { api } from "../../../services/api";
import { SolidButton } from "../../../components/Buttons/SolidButton";


interface QuotasListProps{
    quotas: ReadyQuota[];
    quotaSales: QuotaSale[];
    refetch: () => void;
    startDate: Date | null;
    endDate: Date | null;
}

interface RemovePaymentData{
    id: number;
    title: string;
}

interface PartnersRankingTableData{
    name: string,
    sold_quotas_count?: number,
    sold_quotas_total?: number,
    bought_quotas_count?: number,
    bought_quotas_total?: number,
    processes?: number,
    finished_processes?: number,
}

interface MergedPartners extends PartnerExternal, Partner {}

export function Dashboards({quotas, quotaSales, refetch, startDate, endDate}: QuotasListProps){
    const [partnersRankingTableData, setPartnersRankingTableData] = useState<SortableTableData[]>([]);

    const [partnersExternal, setPartnersExternal] = useState<PartnerExternal[]>([]);
    const [loadingPartnersExternal, setLoadingPartnersExternal] = useState<Boolean>(true);

    const [partners, setPartners] = useState<Partner[]>([]);
    const [loadingPartners, setLoadingPartners] = useState<Boolean>(true);

    const [mergedPartners, setMergedPartners] = useState<Partial<Partner & PartnerExternal>[]>([]);

    const [partnersTableData, setPartnersTableData] = useState<Partner[]>([]);

    const [accessesCount, setAccessesCount] = useState<number>();
    const [loadingAccesses, setLoadingAccesses] = useState<Boolean>(true);

    const [quotaSalesCreditAmount, setQuotaSalesCreditAmount] = useState(0);
    const [quotaSalesValueAmount, setQuotaSalesValueAmount] = useState(0);
    const [quotaSalesProfitAmount, setQuotaSalesProfitAmount] = useState(0);

    const [quotaCreditAmount, setQuotaCreditAmount] = useState(0);
    const [quotaValueAmount, setQuotaValueAmount] = useState(0);
    const [quotaCostAmount, setQuotaCostAmount] = useState(0);

    useEffect(() => {
        const totalQuotasCredit = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
                return sumAmount + quota.credit;
        }, 0);
        setQuotaCreditAmount(totalQuotasCredit);

        const totalQuotasValue = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
            return sumAmount + (quota.value ? quota.value : 0);
        }, 0);

        setQuotaValueAmount(totalQuotasValue);

        const totalQuotasCost = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
            return sumAmount + (quota.cost ? quota.cost : 0);
        }, 0);

        if(quotaSales){
            setQuotaCostAmount(totalQuotasCost);

            const totalQuotaSalesValueAmount = quotaSales.reduce((sumAmount:number, quotaSale:QuotaSale) => {
                return sumAmount + quotaSale.value;
            }, 0);

            setQuotaSalesValueAmount(totalQuotaSalesValueAmount);

            const totalQuotaSalesCreditAmount = quotaSales.reduce((sumAmount:number, quotaSale:QuotaSale) => {

                const quotaSalesValueAmountOfQuotas = quotaSale.sale_ready_quotas.reduce((sumAmount:number, soldQuota:SaleReadyQuota) => {
                    return sumAmount + soldQuota.ready_quota.credit;
                }, 0);

                return sumAmount + quotaSalesValueAmountOfQuotas;
            }, 0);

            setQuotaSalesCreditAmount(totalQuotaSalesCreditAmount);

            const totalQuotaSalesProfitAmount = quotaSales.reduce((sumAmount:number, quotaSale:QuotaSale) => {

                const quotaSalesProfitAmountOfQuotas = quotaSale.sale_ready_quotas.reduce((sumAmount:number, soldQuota:SaleReadyQuota) => {
                    return sumAmount + soldQuota.profit;
                }, 0);

                return sumAmount + quotaSalesProfitAmountOfQuotas;
            }, 0);

            setQuotaSalesProfitAmount(totalQuotaSalesProfitAmount);
        }
    }, [quotas, quotaSales, refetch]);
    
    // const columns = [
    //     { key: "name", label: "Name", sortable: true },
    //     { key: "age", label: "Age", sortable: true },
    //     { key: "city", label: "City", sortable: false },
    //   ];
    
    //   const data = [
    //     { name: "Alice", age: 30, city: "New York" },
    //     { name: "Bob", age: 25, city: "San Francisco" },
    //     { name: "Charlie", age: 35, city: "Chicago" },
    //   ];

    const fetchPartnersExternal = (startDate?:string, endDate?:string) =>{
        api.get('partnersProxy', {params: {start_date: startDate, end_date: endDate}}).then((response) => {
            console.log(response);
            setPartnersExternal(response.data)
            setLoadingPartnersExternal(false);
        })
    }

    const fetchAccesses = () =>{
        api.get('proxy/accessCount').then((response) => {
            setAccessesCount(response.data.count)
            setLoadingAccesses(false);
        })
    }

    const fetchPartners = (startDate?:string, endDate?:string) =>{
        api.get('partners', {params: {start_date: startDate, end_date: endDate}}).then((response) => {
            setPartners(response.data.data)
            setLoadingPartners(false);
        })
    }

    useEffect(() => {
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

        fetchPartnersExternal(firstDayOfMonth.toISOString(), now.toISOString());
        fetchPartners(firstDayOfMonth.toISOString(), now.toISOString());
        fetchAccesses();
    }, [])

    useEffect(() => {
        fetchPartnersExternal(startDate ? formatYmdDate(startDate.toISOString()) : undefined, endDate ? formatYmdDate(endDate.toISOString()) : undefined);
        fetchPartners(startDate ? formatYmdDate(startDate.toISOString()) : undefined, endDate ? formatYmdDate(endDate.toISOString()) : undefined);
        //fetchPartnersExternal(startDate ? formatYmdDate(startDate.toISOString()) : undefined, endDate ? formatYmdDate(endDate.toISOString()) : undefined);
    }, [startDate, endDate]);

    const mergePartnerDataWithDuplicates = (
    externalPartners: PartnerExternal[],
    partners: Partner[]
    ) => {
        // Cria um mapa para consolidar os dados com base no `slug`
        const mergedMap: Record<string, Partial<Partner & PartnerExternal>> = {};
        
        // Adiciona os dados de PartnerExternal ao mapa
        externalPartners.forEach((external) => {
            mergedMap[external.slug] = {
            ...mergedMap[external.slug], // Mantém dados anteriores (caso já tenham sido mesclados)
            ...external, // Adiciona ou sobrescreve dados de PartnerExternal
            };
        });
        
        // Adiciona ou mescla os dados de Partner ao mapa
        partners.forEach((partner) => {
            mergedMap[partner.slug] = {
            ...mergedMap[partner.slug], // Mantém dados anteriores (caso já existam)
            ...partner, // Adiciona ou sobrescreve dados de Partner
            };
        });
        
        // Retorna os valores consolidados do mapa como um array
        return Object.values(mergedMap);
    };

    useEffect(() => {
        const mergedPartnersPartial = mergePartnerDataWithDuplicates(partnersExternal, partners);
        setMergedPartners(mergedPartnersPartial);

        const newPartnersRankingTableData = mergedPartnersPartial.map((mergedPartnerData) => {
            return {
                name: mergedPartnerData.name_display ? mergedPartnerData.name_display : "",
                sums: mergedPartnerData.sums_count ? mergedPartnerData.sums_count : 0,
                sold_quotas_count: mergedPartnerData.sold_quotas_count ? mergedPartnerData.sold_quotas_count : 0,
                sold_quotas_total: mergedPartnerData.sold_quotas_total ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(mergedPartnerData.sold_quotas_total) : 0,
                bought_quotas_count: mergedPartnerData.bought_quotas_count ? mergedPartnerData.bought_quotas_count : 0,
                bought_quotas_total: mergedPartnerData.bought_quotas_total ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(mergedPartnerData.bought_quotas_total) : 0,
                processes: mergedPartnerData.processes ? `${mergedPartnerData.finished_processes}/${mergedPartnerData.processes}` : 0,
                finished_processes: mergedPartnerData.finished_processes ? mergedPartnerData.finished_processes : 0,
            }
        });

        setPartnersRankingTableData(newPartnersRankingTableData);
    }, [partners, partnersExternal]);

    const partnersColumns = [
        {
            key: "name", 
            label: "Empresa", 
            sortable: true
        },
        {
            key: "sold_quotas_total", 
            label: "Vendas", 
            sortable: true
        },
        {
            key: "bought_quotas_total", 
            label: "Compras", 
            sortable: true
        },
        {
            key: "processes", 
            label: "Processos", 
            sortable: true
        },
        {
            key: "sums", 
            label: "Somas", 
            sortable: true
        },
    ]

    return (
        <Stack spacing="8">
            <HStack spacing="8" width="100%">
                <Stack spacing="5" w="100%" minWidth="300px" justify="space-between" alignItems="left" bg="white" borderRadius="16px" shadow="xl" px="8" py="8">
                    <Text fontSize="xl" w="100%">Estoque</Text>

                    <Text fontSize="2xl" w="100%" fontWeight="bold" color={quotaCreditAmount > 0 ? "blue.400" : "red.400"}>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaCreditAmount)}</Text>
                    <Text fontSize="xl" w="100%" >Valor de venda: <b>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaValueAmount)}</b></Text>
                    <Text fontSize="md" w="100%" color="gray.700">Custo: <b>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaCostAmount)}</b></Text>

                    <Link href="/contempladas" display="flex" alignItems="center" fontSize="md" color="gray.700"><CheckIcon width="20px" stroke="#6e7191" fill="none"/> <Text ml="2">Ver estoque</Text></Link>  
                </Stack>

                <Stack spacing="5" w="100%" minWidth="300px" justify="space-between" alignItems="left" bg="white" borderRadius="16px" shadow="xl" px="8" py="8">
                    <Text fontSize="xl" w="100%">Vendas</Text>

                    <Text fontSize="2xl" w="100%" fontWeight="bold" color={quotaSalesCreditAmount > 0 ? "green.400" : "red.400"}>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSalesCreditAmount)}</Text>
                    <HStack>
                        <Text fontSize="xl" color={"gray.700"}>Faturado: </Text>
                        <Text fontSize="xl" color={quotaSalesValueAmount > 0 ? "green.400" : "red.400"}>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSalesValueAmount)}</Text>
                    </HStack>

                    <HStack>
                        <Text fontSize="xl" color={"gray.700"}>Lucro: </Text>
                        <Text fontSize="xl" color={quotaSalesProfitAmount > 0 ? "green.400" : "red.400"}>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSalesProfitAmount)}</Text>
                    </HStack>

                    <Link href="/venda-contempladas" display="flex" alignItems="center" fontSize="md" color="gray.700"><CheckIcon width="20px" stroke="#6e7191" fill="none"/> <Text ml="2">Ver vendas</Text></Link>  
                </Stack>
            </HStack>

            <Stack spacing="5" w="100%" minWidth="300px" justify="space-between" alignItems="left" bg="white" borderRadius="16px" shadow="xl" px="8" py="8">
                <Link href="/parceiros">
                    <SolidButton>Encontrar Parceiro</SolidButton>
                </Link>
                <SortableTable columns={partnersColumns} data={partnersRankingTableData} />
            </Stack>
        </Stack>
    )
}