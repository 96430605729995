import { Button, Flex, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spinner, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CompanySelectMaster } from "../../../components/CompanySelect/companySelectMaster";
import { MainBoard } from "../../../components/MainBoard";
import { QuotaFilterData, useQuotas } from "../../../hooks/useQuotas";
import { QuotaSaleFilterData, useQuotaSales } from "../../../hooks/useQuotaSales";
import { useReadyQuota } from "../../../hooks/useReadyQuota";
import { ReadyQuotaFilterData, useReadyQuotas } from "../../../hooks/useReadyQuotas";
import { useWorkingBranch } from "../../../hooks/useWorkingBranch";
import { useWorkingCompany } from "../../../hooks/useWorkingCompany";
import { Dashboards } from "./Dashboards";
import 'react-datepicker/dist/react-datepicker.css';
import { OutlineButton } from "../../../components/Buttons/OutlineButton";
import '../../../styles/react-datepicker-overrides.css';


export default function Dashboard(){
    const workingCompany = useWorkingCompany();
    const workingBranch = useWorkingBranch();

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [filter, setFilter] = useState<ReadyQuotaFilterData>(() => {
        const data: ReadyQuotaFilterData = {
            search: '',
            company: workingCompany.company?.id,
            branch: workingBranch.branch?.id,
            status: 0,
            sold: 'false',
            is_contemplated: 'true',
        };
        
        return data;
    });

    const [filterSales, setFilterSales] = useState<QuotaSaleFilterData>(() => {
        const data: QuotaSaleFilterData = {
            search: '',
            company: workingCompany.company?.id,
            branch: workingBranch.branch?.id,
            group_by: 'none',
            status: 0,
        };
        
        return data;
    });

    function handleChangeFilter(newFilter: ReadyQuotaFilterData){
        setFilter(newFilter);
    }

    function handleChangeFilterSales(newFilter: QuotaSaleFilterData){
        setFilterSales(newFilter);
    }

    const [page, setPage] = useState(1);

    const quotas = useReadyQuotas(filter, page);
    const quotaSales = useQuotaSales(filterSales, page);

    const [isNewQuotaModalOpen, setIsNewQuotaModalOpen] = useState(false);

    function OpenNewQuotaModal(){
        setIsNewQuotaModalOpen(true);
    }
    function CloseNewQuotaModal(){
        setIsNewQuotaModalOpen(false);
    }

    const handleDateChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {

        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        setStartDate(firstDayOfMonth);
        setEndDate(now);
    }, [])

    useEffect(() => {
        setFilter({...filter, company: workingCompany.company?.id, branch: workingBranch.branch?.id});
    }, [workingCompany, workingBranch]);

    return (
        <MainBoard sidebar="quotas" header={ <CompanySelectMaster/>}
        >
            {/* <StockNavBar OpenNewQuotaModal={OpenNewQuotaModal} />

            <NewQuotaModal afterCreate={quotas.refetch} isOpen={isNewQuotaModalOpen} onRequestClose={CloseNewQuotaModal}/>

            <SearchQuotas filter={filter} handleSearchQuotas={handleChangeFilter} /> */}

            <Stack mb="30px">
                <Popover>
                    <PopoverTrigger>
                        <Button variant="outline" w="280px">
                            {startDate?.toLocaleDateString()} - {endDate?.toLocaleDateString()}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent p="0">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Escolha duas datas</PopoverHeader>
                        <PopoverBody p="0">
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                locale="ptBR"
                                inline
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Stack>

            {
                    quotas.isLoading ? (
                        <Flex justify="center">
                            <Spinner/>
                        </Flex>
                    ) : ( quotas.isError ? (
                        <Flex justify="center" mt="4" mb="4">
                            <Text>Erro listar as cotas em estoque</Text>
                        </Flex>
                    ) : 
                        <Dashboards startDate={startDate} endDate={endDate} quotas={quotas.data?.data} quotaSales={quotaSales.data?.data} refetch={quotas.refetch} />
                    )
            }
            
        </MainBoard>
    )
}