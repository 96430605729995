import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Icon,
} from "@chakra-ui/react";
import { ChevronDown, ChevronUp } from "react-feather";

export interface SortableTableData {
  [key: string]: string | number;
}

interface SortConfig {
  key: string;
  direction: "asc" | "desc";
}

interface Column {
  key: string;
  label: string;
  sortable: boolean;
}

interface SortableTableProps {
  columns: Column[];
  data: SortableTableData[];
  size?: "normal" | "small";
}

const SortableTable: React.FC<SortableTableProps> = ({ columns, data, size = "normal"}) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const sortedData = React.useMemo(() => {
    if (!sortConfig) return data;

    const sorted = [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [data, sortConfig]);

  const handleSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <Table variant="striped" colorScheme="gray" size={size === "small" ? 'sm' : 'md'}>
      <Thead>
        <Tr>
          {columns.map((column) => (
            <Th key={column.key} cursor={column.sortable ? "pointer" : "default"}>
              <chakra.span
                display="inline-flex"
                alignItems="center"
                onClick={column.sortable ? () => handleSort(column.key) : undefined}
              >
                {column.label}
                {column.sortable && sortConfig?.key === column.key ? (
                  sortConfig.direction === "asc" ? (
                    <Icon ml={2} as={ChevronUp} />
                  ) : (
                    <Icon ml={2} as={ChevronDown} />
                  )
                ) : column.sortable ? (
                  <chakra.span ml={2} />
                ) : null}
              </chakra.span>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {sortedData.map((row, rowIndex) => (
          <Tr key={rowIndex}>
            {columns.map((column) => (
              <Td key={column.key} fontSize={size === "small" ? "12px" : ""}>{row[column.key]}</Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default SortableTable;