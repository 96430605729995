import axios from 'axios'
import { getToken } from './auth';

console.log(process.env.REACT_APP_API_LOCAL_URL);

export const api = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_LOCAL_URL
});

api.interceptors.request.use(async config => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.maxRedirects = 5;

    return config;
});

///////////////////////////////////////////////

export const partnerApi = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_LOCAL_URL
});

partnerApi.interceptors.request.use(async config => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});