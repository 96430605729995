import {
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
  Input as ChakraInput,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Spinner,
  Checkbox
} from '@chakra-ui/react'
import { SolidButton } from '../../../../components/Buttons/SolidButton'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../../../services/api'
import { useHistory } from 'react-router'
import { useErrors } from '../../../../hooks/useErrors'

import { State, City } from '../../../../types'
import { useWorkingCompany } from '../../../../hooks/useWorkingCompany'
import { ControlledSelect } from '../../../../components/Forms/Selects/ControlledSelect'
import React, { useEffect, useState } from 'react'
import { isAuthenticated } from '../../../../services/auth'
import { redirectMessages } from '../../../../utils/redirectMessages'

import { ControlledInput } from '../../../../components/Forms/Inputs/ControlledInput'
import { useProfile } from '../../../../hooks/useProfile'
import { useStates } from '../../../../hooks/useStates'
import { useCities } from '../../../../hooks/useCities'

interface NewProcessModalProps {
  isOpen: boolean
  toEditProcessData?: EditProcessFormData;
  onRequestClose: () => void;
  afterEdit: () => void;
}

export interface EditProcessFormData {
  id:number;
  client_name:string;
  partner_id:number;
  group:string;
  quota:string;
  finished: boolean;
}

export interface EditProcessForm {
    client_name:string;
    group:string;
    quota:string;
}

//type EditProcessFormData = Omit<process, "quotas" | "city"  | "state">;

const EditProcessFormSchema = yup.object().shape({
  client_name: yup.string().required('Nome do cliente obrigatório'),
  group: yup.string().required('E-mail do cliente obrigatório'),
  quota: yup.string().required('Telefone do cliente obrigatório'),
})

export function EditProcessModal({
  isOpen,
  onRequestClose,
  afterEdit,
  toEditProcessData,
}: NewProcessModalProps) {
  const workingCompany = useWorkingCompany()
  const { profile, permissions } = useProfile()
  const [isProcessFinished, setProcessFinished] = useState(toEditProcessData?.finished);

  console.log(toEditProcessData);

  const history = useHistory()
  const toast = useToast()
  const { showErrors } = useErrors()

  const { register, handleSubmit, control, reset, watch, formState } =
    useForm<EditProcessForm>({
      resolver: yupResolver(EditProcessFormSchema),
      defaultValues: {
        client_name:toEditProcessData?.client_name,
        group:toEditProcessData?.group,
        quota:toEditProcessData?.quota,
      }
  });

  const handleEditProcess = async (process: EditProcessForm) => {
    console.log(process)
    if(toEditProcessData){
      try {
        if (!workingCompany.company) {
          toast({
            title: 'Ué',
            description: `Seleciona uma empresa para trabalhar`,
            status: 'warning',
            duration: 12000,
            isClosable: true
          })
  
          return
        }
  
        if (!profile) {
          return
        }

        //console.log({...toEditProcessData, ...process});
  
        const response = await api.put(
          `/processes/${toEditProcessData.id}`,
          {...toEditProcessData, ...process, finished: isProcessFinished}
        )
  
        toast({
          title: 'Sucesso',
          description: `O processo ${process.group}-${process.quota} foi atualizado.`,
          status: 'success',
          duration: 12000,
          isClosable: true
        })
  
        // await api.post('/logs/store', {
        //   user: profile.id,
        //   company: workingCompany.company.id,
        //   action: `Alterou as informações do processo ${process.group}-${process.quota}`
        // })
  
        onRequestClose()
        afterEdit()
        reset()
      } catch (error: any) {
        showErrors(error, toast)
  
        if (error.response.data.access) {
          history.push('/')
        }
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push({
        pathname: '/',
        state: redirectMessages.auth
      })
    }
  }, [isOpen])

  const states = useStates();

  return (
    <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
      <ModalOverlay />
      <ModalContent
        as="form"
        borderRadius="24px"
        onSubmit={handleSubmit(handleEditProcess)}
      >
        {
          toEditProcessData && (
            <>
              <ModalHeader p="10" fontWeight="700" fontSize="2xl">
                Alterar processo grupo:{toEditProcessData.group} cota:{toEditProcessData.quota}
              </ModalHeader>

              <ModalCloseButton top="10" right="5" />

              <ModalBody pl="10" pr="10">
                <Stack spacing="6">
                  <ControlledInput
                    control={control}
                    value={toEditProcessData.client_name}
                    name="client_name"
                    type="text"
                    placeholder="Nome do Cliente"
                    focusBorderColor="blue.800"
                    variant="outline"
                    error={formState.errors.client_name}
                  />

                  <HStack spacing="4" align="baseline">
                    <ControlledInput
                      control={control}
                      value={toEditProcessData.group}
                      name="group"
                      type="text"
                      placeholder="Grupo"
                      focusBorderColor="blue.800"
                      variant="outline"
                      error={formState.errors.group}
                    />

                    <ControlledInput
                      control={control}
                      value={toEditProcessData.quota}
                      name="quota"
                      type="text"
                      placeholder="Número de telefone"
                      focusBorderColor="blue.800"
                      variant="outline"
                      error={formState.errors.quota}
                    />
                  </HStack>

                  <Divider />

                  <Checkbox label="Processo finalizado" name="finished" checked={isProcessFinished} onChange={(event) => setProcessFinished(!!event.target?.checked)}>
                  Processo finalizado
                  </Checkbox>
                </Stack>
              </ModalBody>

              <ModalFooter p="10">
                <SolidButton
                  mr="6"
                  color="white"
                  bg="blue.800"
                  colorScheme="blue"
                  type="submit"
                  isLoading={formState.isSubmitting}
                >
                  Atualizar
                </SolidButton>

                <Link onClick={onRequestClose} color="gray.700" fontSize="14px">
                  Cancelar
                </Link>
              </ModalFooter>
            </>
          )
        }
        
      </ModalContent>
    </Modal>
  )
}
